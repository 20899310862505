import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class GuardGuard implements CanActivate {
  routeURL;

  constructor(private router: Router,
    private authService: AuthService,
    
    ){
    }
  canActivate(
    route: ActivatedRouteSnapshot, state: RouterStateSnapshot

    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      console.log('route', );
      console.log('this.authService.currentUserValue?.user?.role?.name', this.authService.currentUserValue?.user?.role?.name);

      if ( this.authService.currentUserValue?.token && this.authService.currentUserValue?.user?.confirmed) {
        this.routeURL = state.url;
        /* if(
          this.routeURL !== '/transactions' && 
          this.authService.currentUserValue?.user?.client?.plan?.name == "Basico") {
            debugger
          this.router.navigate(['transactions']);
          return false;
        }        */        
        if (route.data.roles && route.data.roles.indexOf(this.authService.currentUserValue?.user?.role?.name) === -1) {
          if(this.authService.currentUserValue?.user?.role?.name === "Administrador de solicitudes") {
            this.router.navigate(['/admin/transactions/requests']);
            return false;
          }
          if(this.authService.currentUserValue?.user?.role?.name === "Administrador") {
            this.router.navigate(['/admin/clients']);
            return false;
          }
          this.router.navigate(['/']);
          return false;
        }

        return true
      }

      this.router.navigate(['/auth/login']);
      return false;
  }
  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }
  
}
