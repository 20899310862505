import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "../../services/auth.service";

var misc: any = {
  sidebar_mini_active: true
};

export interface RouteInfo {
  path: string;
  title: string;
  type: string;
  icontype: string;
  collapse?: string;
  isCollapsed?: boolean;
  isCollapsing?: any;
  children?: ChildrenItems[];
  role?: string[];
  availablePlans?: string[];
}

export interface ChildrenItems {
  path: string;
  title: string;
  type?: string;
  collapse?: string;
  children?: ChildrenItems2[];
  isCollapsed?: boolean;
}
export interface ChildrenItems2 {
  path?: string;
  title?: string;
  type?: string;
}
//Menu Items
export const ROUTES: RouteInfo[] = [
  /* {
    path: "/dashboards",
    title: "Dashboards",
    type: "sub",
    icontype: "ni-shop text-primary",
    isCollapsed: true,
    children: [
      { path: "dashboard", title: "Dashboard", type: "link" },
      { path: "alternative", title: "Alternative", type: "link" }
    ]
  }, */
  {
    path: "/dashboard",
    title: "DASHBOARD",
    type: "link",
    icontype: "./assets/img/icons/sidebar/dashboard.svg",
    role: ["SuperAdministrador", "Cliente", "Administrador Financiero"],
    availablePlans: ["Profesional"]
  },
  {
    path: "/admin/clients",
    title: "CLIENTES",
    type: "link",
    icontype: "./assets/img/icons/sidebar/clients.svg",
    role: ["SuperAdministrador", "Administrador"],
    availablePlans: ["Basico", "Profesional"]
  },
  {
    path: "/transactions",
    title: "TRANSACCIONES",
    type: "link",
    icontype: "./assets/img/icons/sidebar/transactions.svg",
    role: ["Cliente"],
    availablePlans: ["Basico", "Profesional"]
  },
  {
    path: "/payments",
    title: "PAGOS",
    type: "link",
    icontype: "./assets/img/icons/sidebar/payments.svg",
    role: ["Cliente"],
    availablePlans: ["Basico", "Profesional"]
  },
  {
    path: "/employees",
    title: "EMPLEADOS",
    type: "link",
    icontype: "./assets/img/icons/sidebar/employees.svg",
    role: ["Cliente"],
    availablePlans: ["Basico", "Profesional"]
  },
  {
    path: "/education",
    title: "EDUCACIÓN",
    type: "link",
    icontype: "./assets/img/icons/sidebar/education.svg",
    role: ["Cliente", "Administrador"],
    availablePlans: ["Profesional"]
  },
  {
    path: "/reports",
    title: "REPORTERÍA",
    type: "link",
    icontype: "./assets/img/icons/sidebar/reports.svg",
    role: ["Cliente"],
    availablePlans: ["Basico", "Profesional"]
  },
  {
    path: "/bills",
    title: "DOCUMENTOS",
    type: "link",
    icontype: "./assets/img/icons/sidebar/bills.svg",
    role: ["Cliente"],
    availablePlans: ["Basico", "Profesional"]

    /* availablePlans: [] */
  },
  /* ADMIN */
  {
    path: "/admin/transactions",
    title: "TRANSACCIONES",
    type: "link",
    icontype: "./assets/img/icons/sidebar/transactions.svg",
    role: ["SuperAdministrador", "Administrador Financiero"],
    /* availablePlans: [] */
  },
  {
    path: "/admin/incomes",
    title: "INGRESOS",
    type: "link",
    icontype: "./assets/img/icons/sidebar/incomes.svg",
    role: ["SuperAdministrador", "Administrador Financiero"],
    /* availablePlans: [] */
  },
  {
    path: "/admin/reports",
    title: "REPORTERÍA",
    type: "link",
    icontype: "./assets/img/icons/sidebar/reports.svg",
    role: ["SuperAdministrador", "Administrador Financiero", "Administrador"],
    /* availablePlans: [] */
  },
  {
    path: "/admin/transactions/requests",
    title: "Solicitudes",
    type: "link",
    icontype: "./assets/img/icons/sidebar/transactions.svg",
    role: ["Administrador de solicitudes"],
    /* availablePlans: [] */
  },
  {
    path: "/admin/training",
    title: "EDUCACIÓN",
    type: "link",
    icontype: "./assets/img/icons/sidebar/education.svg",
    role: ["SuperAdministrador"],
    /* availablePlans: [] */
  },
  {
    path: "/admin/administrators",
    title: "ADMINISTRADORES",
    type: "link",
    icontype: "./assets/img/icons/sidebar/administrators.svg",
    role: ["SuperAdministrador"],
    /* availablePlans: [] */
  },
  {
    path: "/admin/bills",
    title: "DOCUMENTOS",
    type: "link",
    icontype: "./assets/img/icons/sidebar/bills.svg",
    role: ["SuperAdministrador"],
    /* availablePlans: [] */
  },
];

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"]
})
export class SidebarComponent implements OnInit {
  public menuItems: any[];
  public isCollapsed = true;
  public user;
  constructor(private router: Router, private authService: AuthService) { }

  ngOnInit() {
    this.menuItems = ROUTES.filter(menuItem => menuItem);
    this.router.events.subscribe(event => {
      this.isCollapsed = true;
    });
    this.user = this.authService.currentUserValue;
  }
  onMouseEnterSidenav() {
    if (!document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.add("g-sidenav-show");
    }
  }
  onMouseLeaveSidenav() {
    if (!document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-show");
    }
  }
  minimizeSidebar() {
    const sidenavToggler = document.getElementsByClassName(
      "sidenav-toggler"
    )[0];
    const body = document.getElementsByTagName("body")[0];
    if (body.classList.contains("g-sidenav-pinned")) {
      misc.sidebar_mini_active = true;
    } else {
      misc.sidebar_mini_active = false;
    }
    if (misc.sidebar_mini_active === true) {
      body.classList.remove("g-sidenav-pinned");
      body.classList.add("g-sidenav-hidden");
      sidenavToggler.classList.remove("active");
      misc.sidebar_mini_active = false;
    } else {
      body.classList.add("g-sidenav-pinned");
      body.classList.remove("g-sidenav-hidden");
      sidenavToggler.classList.add("active");
      misc.sidebar_mini_active = true;
    }
  }
  logout() {
    this.authService.logout();
  }
}
