<nav
  class="navbar navbar-top navbar-expand navbar-dark bg-default border-bottom"
  id="navbar-main"
>
  <div class="container-fluid">
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav align-items-center">
        <li class="nav-item d-xl-none">
          <!-- Sidenav toggler -->
          <div
            class="pr-3 sidenav-toggler sidenav-toggler-dark"
            data-action="sidenav-pin"
            data-target="#sidenav-main"
            (click)="openSidebar()"
          >
            <div class="sidenav-toggler-inner">
              <i class="sidenav-toggler-line"></i>
              <i class="sidenav-toggler-line"></i>
              <i class="sidenav-toggler-line"></i>
            </div>
          </div>
        </li>
      </ul>
      <div>
        <h4 class="text-white">{{activeRouteTitle}}</h4>
        <h2 class="text-white mb-0">{{activeRouteTitle}}</h2>
      </div>
      
    </div>
  </div>
</nav>
<div
  *ngIf="sidenavOpen === true"
  class="backdrop d-xl-none"
  (click)="toggleSidenav()"
></div>
