import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';

import { AuthService } from '../services/auth.service';
import { SpinnerService } from '../services/spinner.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthService, private spinnerService: SpinnerService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.spinnerService.llamarSpinner()

        return next.handle(request).pipe(
            catchError(err => {
            console.log('err', err);
            
            /* if (err.status === 401) {
                debugger
                // auto logout if 401 response returned from api
                this.authenticationService.logout();
            }
            else if (err.status === 403) {
                // auto logout if 401 response returned from api
                this.authenticationService.logout();
            } */
            if (err.error.message == "Token no válido o sin autenticación presente"){
                this.authenticationService.logout();
            }            
            //const error = err.error.message || err.statusText;
            const error = err;
            return throwError(error);
        }),

        finalize(() => this.spinnerService.detenerSpinner())
        )
    }
}