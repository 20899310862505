<nav
  class="sidenav navbar navbar-vertical fixed-left navbar-expand-xs navbar-light bg-white"
  id="sidenav-main"
  (mouseover)="onMouseEnterSidenav()"
  (mouseout)="onMouseLeaveSidenav()"
>
<perfect-scrollbar>
  <div class="scrollbar-inner">
    <div class="sidenav-header d-flex align-items-center">
      <a class="navbar-brand mx-auto" [routerLink]="['/dashboard']">
        <img
          src="assets/img/brand/logo.png"
          class="navbar-brand-img"
          alt="..."
        />
      </a>
      <div class="ml-auto">
        <!-- Sidenav toggler -->
        <div
          class="sidenav-toggler d-none d-xl-block"
          data-action="sidenav-unpin"
          data-target="#sidenav-main"
          (click)="minimizeSidebar()"
        >
          <div class="sidenav-toggler-inner">
            <i class="sidenav-toggler-line"></i>
            <i class="sidenav-toggler-line"></i>
            <i class="sidenav-toggler-line"></i>
          </div>
        </div>
      </div>
    </div>
    <div class="navbar-inner">
      <div class="profile">
          <!-- <div class="wrap-profile-pictures">
            <img class="user-picture rounded-circle" alt="Image placeholder" [src]="user.user.avatar" onerror="this.src='./assets/img/user-placeholder.png';">
            <img *ngIf="user.user?.client?.avatar" class="user-company rounded-circle" alt="Image placeholder" [src]="user.user?.client?.avatar">
          </div> -->
          <div class="wrap-profile-pictures">
            <img *ngIf="user.user?.client?.avatar" class="user-company rounded-circle" alt="Image placeholder" [src]="user.user?.client?.avatar">
          </div>
          <h4>Hola, <span class="username" title="{{user.user.fullName}}">{{user.user.fullName}}</span></h4>
          <h5 class="text-muted"> {{user.user.client?.name}}</h5>
      </div>
      <div class="collapse navbar-collapse" id="sidenav-collapse-main">
        <!-- Collapse header -->
        <ul class="navbar-nav">
          <ng-container *ngFor="let menuitem of menuItems">
            <ng-container *ngIf="menuitem.role.indexOf(user.user?.role?.name) > -1">
              <!--If is a single link-->
              <li  class="nav-item" tooltip="Plan Profesional es necesario"
              placement="top"     
              [isDisabled]="user?.user?.client?.plan?.name == 'Basico' && user.user.role.name == 'Cliente' ? menuitem?.availablePlans?.includes('Basico') : true"              >
                <a
                  routerLinkActive="active"
                  [routerLink]="[menuitem.path]"
                  *ngIf="menuitem.type === 'link'"
                  class="nav-link"
                  [class.disabled]="user?.user?.client?.plan?.name == 'Basico' && user.user.role.name == 'Cliente' ? !menuitem?.availablePlans?.includes('Basico') : false"
                >
                  <img src="{{ menuitem.icontype }}" class="mr-2" />
<!--                   <span class="nav-link-text">{{ menuitem.title }}</span>
 -->                  <span class="nav-link-text">{{ menuitem?.title}}</span>
                </a>
                <!--If it have a submenu-->
                <a
                  data-toggle="collapse"
                  routerLinkActive="active"
                  *ngIf="menuitem.type === 'sub'"
                  (click)="menuitem.isCollapsed = !menuitem.isCollapsed"
                  [attr.aria-expanded]="!menuitem.isCollapsed"
                  [attr.aria-controls]="menuitem.collapse"
                  class="nav-link"
                >
                  <i class="ni {{ menuitem.icontype }}"></i>
                  <span class="nav-link-text">{{ menuitem.title }}</span>
                </a>
    
                <!--Display the submenu items-->
                <div
                  id="{{ menuitem.collapse }}"
                  class="collapse"
                  *ngIf="menuitem.type === 'sub'"
                  [collapse]="menuitem.isCollapsed"
                  [isAnimated]="true"
                >
                  <ul class="nav nav-sm flex-column">
                    <li
                      *ngFor="let childitems of menuitem.children"
                      class="nav-item"
                    >
                      <!--If is a single link-->
                      <a
                        routerLinkActive="active"
                        [routerLink]="[menuitem.path, childitems.path]"
                        class="nav-link"
                        *ngIf="childitems.type === 'link'"
                      >
                        {{ childitems.title }}
                      </a>
                      <!--If it have a submenu-->
                      <a
                        data-toggle="collapse"
                        (click)="childitems.isCollapsed = !childitems.isCollapsed"
                        [attr.aria-expanded]="!childitems.isCollapsed"
                        [attr.aria-controls]="childitems.collapse"
                        *ngIf="childitems.type === 'sub'"
                        class="nav-link"
                      >
                        {{ childitems.title }}
                      </a>
                      <!--Display the submenu items-->
                      <div
                        id="{{ childitems.collapse }}"
                        class="collapse"
                        *ngIf="childitems.type === 'sub'"
                        [collapse]="childitems.isCollapsed"
                        [isAnimated]="true"
                      >
                        <ul class="nav">
                          <li
                            *ngFor="let childitem of childitems.children"
                            class="nav-item"
                          >
                            <a href="javascript:void(0)" class="nav-link">
                              {{ childitem.title }}
                            </a>
                          </li>
                        </ul>
                      </div>
                    </li>
                  </ul>
                </div>
              </li>
            </ng-container>
          </ng-container>
        </ul>
        <!-- Divider -->
        <hr class="my-3" />
        <!-- Heading -->
        <!-- <h6 class="navbar-heading p-0 text-muted">Documentation</h6> -->
        <!-- Navigation -->
        <ul class="navbar-nav mb-md-3">
          <li class="nav-item" style="cursor: pointer;">
            <a
              class="nav-link"
              (click)="logout()"
            >
              <img class="mr-2" src="./assets/img/icons/sidebar/logout.svg" alt="">
              <span class="nav-link-text">CERRAR SESIÓN</span>
            </a>
          </li>
<!--           <li class="nav-item">
            <a
              class="nav-link"
              href="https://demos.creative-tim.com/argon-dashboard-pro-angular/#/documentation/overview?ref=adpa-sidebar-area"
              target="_blank"
            >
              <i class="ni ni-spaceship"></i>
              <span class="nav-link-text">Getting started</span>
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              href="https://demos.creative-tim.com/argon-dashboard-pro-angular/#/documentation/colors?ref=adpa-sidebar-area"
              target="_blank"
            >
              <i class="ni ni-palette"></i>
              <span class="nav-link-text">Foundation</span>
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              href="https://demos.creative-tim.com/argon-dashboard-pro-angular/#/documentation/alerts?ref=adpa-sidebar-area"
              target="_blank"
            >
              <i class="ni ni-ui-04"></i>
              <span class="nav-link-text">Components</span>
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              href="https://demos.creative-tim.com/argon-dashboard-pro-angular/#/documentation/charts?ref=adpa-sidebar-area"
              target="_blank"
            >
              <i class="ni ni-chart-pie-35"></i>
              <span class="nav-link-text">Plugins</span>
            </a>
          </li> -->
        </ul>
      </div>
    </div>
  </div>
</perfect-scrollbar>

</nav>
