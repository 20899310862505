import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  public httpOptions:any;
  public filesHttpOptions:any;
  public userLocalStorage: any;
  public API_URL = environment.api_url;
  constructor(private http: HttpClient) {
  this.userLocalStorage =  JSON.parse(localStorage.getItem('user'));
  if (this.userLocalStorage) {
      this.setHttpOptions(this.userLocalStorage)
    }
  }

  setHttpOptions(data) {
    localStorage.setItem('user', JSON.stringify(data));
      this.httpOptions = {
          headers: new HttpHeaders({
                  'Authorization': `Bearer ${data.token}`,
                  'Content-type': 'application/json'
              }
          )
      };

      this.filesHttpOptions = {
          headers: new HttpHeaders({
              'Authorization': `Bearer ${JSON.parse(JSON.stringify(localStorage.getItem(data)))}`,
          })
      }
  }
  getTransactionsClient(dateStart, dateEnd, employeeId, skip, limit){    
    let queryParams = `?skip=${skip}&limit=${limit}`;
    if(dateStart && dateEnd){
      queryParams += `&since=${dateStart}&until=${dateEnd}`
    }
    if(employeeId){
      queryParams += `&employee=${employeeId}`
    }
    return this.http.get(`${this.API_URL}/client-panel/transactions/get-transactions-by-date${queryParams}`, this.httpOptions);
  }
  getEmployeesPaymentsClient(month, employeeId, skip){    
    let queryParams = `?skip=${skip}`;
    if(month){
      queryParams += `&month=${month}`
    }
    if(employeeId){
      queryParams += `&employee=${employeeId}`
    }
    /* if(employeeId && !date){
      queryParams = `?employee=${employeeId}&skip=${skip}`
    }
    else if(!employeeId && date){
      queryParams = `?date=${date}&skip=${skip}`
    }
    else if(employeeId && date){
      queryParams = `?employee=${employeeId}&date=${date}&skip=${skip}`
    }
    else {  
        queryParams = `?skip=${skip}`
    } */
    return this.http.get(`${this.API_URL}/client-panel/payments/get-employees-payments${queryParams}`, this.httpOptions);
  }
  getEmployees(){
    return this.http.get(`${this.API_URL}/client-panel/employees/get-company-employees`, this.httpOptions);
  }
  /* ========= CLIENTS ADMIN ============ */
  getClients(page, planId, clientId){
    let queryParams = '';
    if(planId && !clientId){
      queryParams = `?plan=${planId}&skip=${page}`
    }
    else if(!planId && clientId){
      queryParams = `/${clientId}?skip=${page}`
    }
    else if(planId && clientId){
      queryParams = `/${clientId}?plan=${planId}&skip=${page}`
    }
    else {  
        queryParams = `?skip=${page}`
    }
    return this.http.get(`${this.API_URL}/client${queryParams}`, this.httpOptions);
  }
  getPlans(){
    return this.http.get(`${this.API_URL}/plan`, this.httpOptions);
  }
  getClientsFile(planId, clientId){
    let queryParams = '';
    if(planId && !clientId){
      queryParams = `?plan=${planId}`
    }
    else if(!planId && clientId){
      queryParams = `/${clientId}`
    }
    else if(planId && clientId){
      queryParams = `/${clientId}?plan=${planId}`
    }
    else {  
        queryParams = ''
    }
    return this.http.get(`${this.API_URL}/admin-panel/clients/get-clients-file${queryParams}`, {...this.filesHttpOptions,  responseType: 'blob'});
  }

  
  editEmployee(userId, employee){
    return this.http.patch(`${this.API_URL}/employee/${userId}`, employee, this.httpOptions);
  }
  createEmployee(employee){
    return this.http.post(`${this.API_URL}/employee`, employee, this.httpOptions);
  }
  /* CLIENT ADMIN */
  createClient(client){
    return this.http.post(`${this.API_URL}/client`, client, this.httpOptions);
  }
  editClient(client, id){
    return this.http.patch(`${this.API_URL}/client/${id}`, client, this.httpOptions);
  }
  getClient(client){
    return this.http.get(`${this.API_URL}/client/${client}`, this.httpOptions);
  }
  deleteClient(client){
    return this.http.delete(`${this.API_URL}/client/${client}`, this.httpOptions);
  }
  getAllDepartments(){
    return this.http.get(`${this.API_URL}/department?company=${JSON.parse(localStorage.getItem('user'))?.user?.client?.id}`, this.httpOptions);
  }
  getNationalities(){
    return this.http.get(`${this.API_URL}/nationality?sort=default DESC`, this.httpOptions);
  }
  getModalities(){
    return this.http.get(`${this.API_URL}/modality`, this.httpOptions);
  }
  getCivilStates(){
    return this.http.get(`${this.API_URL}/civilStatus`, this.httpOptions);
  }
  getAllCompanies(){
    return this.http.get(`${this.API_URL}/client`, this.httpOptions);
  }
  /* END CLIENT ADMIN */

  /* DEPARTMENTS ADMIN */
  getDepartments(page, clientId){
    return this.http.get(`${this.API_URL}/department?skip=${page}&company=${clientId}`, this.httpOptions);
  }
  updateDepartment(departmentId, data){
    return this.http.patch(`${this.API_URL}/department/${departmentId}`, data, this.httpOptions);
  }
  createDepartment(data){
    return this.http.post(`${this.API_URL}/department/`, data, this.httpOptions);
  }
  deleteDepartment(id){
    return this.http.delete(`${this.API_URL}/department/${id}`, this.httpOptions);
  }
  /* END DEPARTMENTS ADMIN */
  /* EMPLOYEES ADMIN */
  getEmployeeByIdAdmin(clientId, employeeId){
    return this.http.get(`${this.API_URL}/employee/${employeeId}`, this.httpOptions);
  }
  updateEmployeeRoleAdmin(id, employee){
    return this.http.patch(`${this.API_URL}/employee/${id}`, employee, this.httpOptions);
  }
  createEmployeeRoleAdmin(employee){
    return this.http.post(`${this.API_URL}/employee`, employee, this.httpOptions);
  }
  getCompanyById(id){
    return this.http.get(`${this.API_URL}/client/${id}`, this.httpOptions);
  }
  uploadEmployeesFile(id, file){
    return this.http.post(`${this.API_URL}/admin-panel/employee/massive-load-of-employees?company=${id}`, file, {...this.filesHttpOptions});
  }

  /* END EMPLOYEES ADMIN */

  /* TRANSACTIONS MODULE ADMIN */
  getAllTransactionsAdmin(dateStart, dateEnd, company, skip){
    let queryParams = '';
    if (dateStart && dateEnd && !company){
      queryParams = `?since=${dateStart}&until=${dateEnd}&skip=${skip}`
    }
    else if (!dateStart && !dateEnd && company){
      queryParams = `?company=${company}&skip=${skip}`
    }
    else if (dateStart && dateEnd && company){
      queryParams = `?since=${dateStart}&until=${dateEnd}&company=${company}&skip=${skip}`
    }
    else {  
      queryParams = `?skip=${skip}`
    }
    return this.http.get(`${this.API_URL}/admin-panel/transaction/get-transactions${queryParams}`, this.httpOptions);
  }
  getClientsDetailsAdmin(dateStart, dateEnd, company, skip){
    let queryParams = '';
    if (dateStart && dateEnd && !company){
      queryParams = `?since=${dateStart}&until=${dateEnd}&skip=${skip}`
    }
    else if (!dateStart && !dateEnd && company){
      queryParams = `?company=${company}&skip=${skip}`
    }
    else if (dateStart && dateEnd && company){
      queryParams = `?since=${dateStart}&until=${dateEnd}&company=${company}&skip=${skip}`
    }
    else {  
      queryParams = `?skip=${skip}`
    }
    return this.http.get(`${this.API_URL}/transaction/get-clients-details${queryParams}`, this.httpOptions);
  }
  /* pending transactions */
  getPendingTransactions(skip){
    return this.http.get(`${this.API_URL}/admin-panel/transactions/get-pending-transactions?skip=${skip}`, this.httpOptions);
  }
  getPendingTransactionsFile(){
    return this.http.get(`${this.API_URL}/admin-panel/transactions/get-pending-transactions-file`, {...this.filesHttpOptions,  responseType: 'blob'});
  }

  /* TRANSACTIONS INDICATORS */
  getTransactionsIndicatorsAdmin(){    
    return this.http.get(`${this.API_URL}/transaction/transactions-monthly-indicators`, this.httpOptions);
  }
  /* TRANSACTIONS CHART */
  getMonthlyTransactionsAdmin(dateStart, dateEnd, company) {
    let queryParams = '';
    if (dateStart && dateEnd && !company){
      queryParams = `?since=${dateStart}&until=${dateEnd}`
    }
    else if (!dateStart && !dateEnd && company){
      queryParams = `?company=${company}`
    }
    else if (dateStart && dateEnd && company){
      queryParams = `?since=${dateStart}&until=${dateEnd}&company=${company}`
    }
    else {  
      queryParams = ''
    }
    return this.http.get(`${this.API_URL}/transaction/monthly-transactions${queryParams}`, this.httpOptions);
  }
  /* END: TRANSACTIONS MODULE ADMIN */



  /* TRAINING MODULE ADMIN */

  getAllVideosAdmin(skip = 0) {
    return this.http.get(`${this.API_URL}/video?skip=${skip}`, this.httpOptions);
  }

  createVideoAdmin(data) {
    return this.http.post(`${this.API_URL}/video`, data, this.httpOptions);
  }

  editVideoAdmin(data, id) {
    return this.http.patch(`${this.API_URL}/video/${id}`, data, this.httpOptions);
  }

  deleteVideoAdmin(id) {
    return this.http.delete(`${this.API_URL}/video/${id}`, this.httpOptions);
  }


  createVideoCategory(data) {
    return this.http.post(`${this.API_URL}/category`, data, this.httpOptions);
  }

  editVideoCategory(data, id) {
    return this.http.patch(`${this.API_URL}/category/${id}`, data, this.httpOptions);
  }


  deleteVideoCategory(id) {
    return this.http.delete(`${this.API_URL}/category/${id}`, this.httpOptions);
  }

  getVideosViewsAdmin(skip,videoId, companyId = "") {
    let filters = `&video=${videoId}`;
    if(companyId) {
      filters += `&company=${companyId}`;
    }
    return this.http.get(`${this.API_URL}/admin-panel/education/get-video-views?skip=${skip}${filters}&sort=Z-A`, this.httpOptions);
  }

  getVideoViewsFileAdmin(videoId, companyId = ""){
    let filters = `video=${videoId}`;
    if(companyId) {
      filters += `&company=${companyId}`;
    }
    return this.http.get(`${this.API_URL}//admin-panel/education/get-video-views-file?${filters}&sort=A-Z`, {...this.filesHttpOptions,  responseType: 'blob'});
  }


  /* END TRAINING MODULE ADMIN */
/* ADMINISTRATORS MODULE ADMIN */

getAdministratorsRolesAdmin(skip = 0) {
  return this.http.get(`${this.API_URL}/user/get-admin-roles`, this.httpOptions);
}
getAllAdministratorsAdmin(skip = 0) {
  return this.http.get(`${this.API_URL}/user/get-admins?skip=${skip}`, this.httpOptions);
}

createAdministratorAdmin(data) {
  return this.http.post(`${this.API_URL}/user/create-admin`, data, this.httpOptions);
}

editAdministratorAdmin(data, id) {
  return this.http.patch(`${this.API_URL}/user/update-admin/${id}`, data, this.httpOptions);
}

deleteAdministratorAdmin(id) {
  return this.http.delete(`${this.API_URL}/user/delete-admins/${id}`, this.httpOptions);
}

/* END ADMINISTRATORS MODULE ADMIN */
  

  getAllAccountTypes(bankId){
    let queryParams = '';
    if (JSON.parse(localStorage.getItem('user'))?.user?.client?.currency && !bankId){
      queryParams = `&currency=${JSON.parse(localStorage.getItem('user'))?.user?.client?.currency}`
    }
    if (bankId && !JSON.parse(localStorage.getItem('user'))?.user?.client?.currency){
      queryParams = `?bank=${bankId}`
    }
    if (JSON.parse(localStorage.getItem('user'))?.user?.client?.currency && bankId){
      queryParams = `?currency=${JSON.parse(localStorage.getItem('user'))?.user?.client?.currency}&bank=${bankId}`
    }
    return this.http.get(`${this.API_URL}/accountType${queryParams}`, this.httpOptions);
  }
  /* END EMPLOYEES ADMIN */
  deleteEmployee(userId){
    return this.http.delete(`${this.API_URL}/employee/${userId}`, this.httpOptions);
  }
  getEmployeesClient( activeSortFilter, skip, limit){
    let queryParams = `?skip=${skip}&limit=${limit}`;
    
    if(activeSortFilter == 'true' || activeSortFilter == 'false'){
      queryParams += `&active=${activeSortFilter}`
    }
    else if(activeSortFilter == 'ASC' || activeSortFilter == 'DESC'){
      queryParams += `&sort=${activeSortFilter}`
    }
    return this.http.get(`${this.API_URL}/client-panel/employees/get-company-employees${queryParams}`, this.httpOptions);
  }
  getEmployeeById(id){
    return this.http.get(`${this.API_URL}/employee/${id}`, this.httpOptions);
  }
  getDepartmentsByCompanyId(skip, limit){
    let queryParams = "";
    if (skip && limit) {
      queryParams = `&skip=${skip}&limit=${limit}`;
    }
    return this.http.get(`${this.API_URL}/client-panel/employees/get-employees-by-department?company=${JSON.parse(localStorage.getItem('user'))?.user?.client?.id}${queryParams}`, this.httpOptions);
  }
  getBanks(){
    return this.http.get(`${this.API_URL}/bank`, this.httpOptions);
  }
  getAccountTypes(bankId, currency){
    let queryParams = '?limit=100';
    if(bankId){
      queryParams+= `&bank=${bankId}`
    }
    if(currency){
      queryParams+= `&currency=${currency}`
    }
    return this.http.get(`${this.API_URL}/accountType${queryParams}`, this.httpOptions);
  }
  getModifiedEmployees(departmentId){
    return this.http.get(`${this.API_URL}/client-panel/employees/get-modified-employees?company=${JSON.parse(localStorage.getItem('user'))?.user?.client?.id}&department=${departmentId}&modified=false`, this.httpOptions);
  }
  updateMaximumWithdrawalPercentage(data){
    return this.http.patch(`${this.API_URL}/client-panel/employees/mass-percentage-update`, data, this.httpOptions);
  }
  getEducationVideos( activeCategoryFilter, skip){
    let queryParams = '';    
    if(activeCategoryFilter){
      queryParams = `?category=${activeCategoryFilter}&skip=${skip}`
    }
/*     else if(activeCategoryFilter == 'ASC' || activeCategoryFilter == 'DESC'){
      queryParams = `?sort=${activeCategoryFilter}&skip=${skip}`
    } */
    else {  
        queryParams = `?skip=${skip}`
    }
    return this.http.get(`${this.API_URL}/client-panel/videos/get-employees-views${queryParams}`, this.httpOptions);
  }
  getEducationCategories(skip?){
    let queryParams = '';
    if(skip || skip == 0) {
      queryParams += `?skip=${skip}`;
    }
    return this.http.get(`${this.API_URL}/category${queryParams}`, this.httpOptions);
  }
  getGeneralReporting( startMonth, endMonth, skip){
    let queryParams = `?limit=10&skip=${skip}`;

    if(startMonth && endMonth){
      queryParams += `&monthSince=${startMonth}&monthUntil=${endMonth}`
    }
    return this.http.get(`${this.API_URL}/client-panel/reporting/general-reporting${queryParams}`, this.httpOptions);
  }
  getTransactionsFile(dateStart, dateEnd, employeeId){
    let queryParams = "";
    if(dateStart && dateEnd){
      queryParams += `?since=${dateStart}&until=${dateEnd}`
    }
    else if(employeeId){
      queryParams += `?employee=${employeeId}`
    }
    else if(dateStart && dateEnd && employeeId) {
      queryParams += `?since=${dateStart}&until=${dateEnd}&employee=${employeeId}`
    }
    return this.http.get(`${this.API_URL}/client-panel/transactions/get-transactions-file${queryParams}`, {...this.filesHttpOptions,  responseType: 'blob'});
  }
  getPaymentsFile(user, month){
    let queryParams = '';
    if(user && !month){
      queryParams = `?user=${user}`
    }
    else if(!user && month){
      queryParams = `?month=${month}`
    }
    else if(user && month){
      queryParams = `?user=${user}&month=${month}`
    }

    return this.http.get(`${this.API_URL}/client-panel/reporting/get-report-file${queryParams}`, {...this.filesHttpOptions,  responseType: 'blob'});
  }
  getEmployeesFile(activeSortFilter){
    let queryParams = '';
    console.log('activeSortFilter', activeSortFilter);
    
    if(activeSortFilter == 'true' || activeSortFilter == 'false'){
      queryParams = `?active=${activeSortFilter}`
    }
    else if(activeSortFilter == 'ASC' || activeSortFilter == 'DESC'){
      queryParams = `?sort=${activeSortFilter}`
    }

    return this.http.get(`${this.API_URL}/client-panel/employees/get-employees-file${queryParams}`, {...this.filesHttpOptions,  responseType: 'blob'});
  }
  getEmployeesReport( activeAmountFilter, startMonth, endMonth, skip, mainReport){
    let queryParams = `?limit=10&skip=${skip}`;
    if(activeAmountFilter){
      queryParams += `&filter=${activeAmountFilter}`
    }
    if(startMonth && endMonth){
      queryParams += `&monthSince=${startMonth}&monthUntil=${endMonth}`
    }
    if (!mainReport) {
      queryParams += `&mainReport=false`
    }
    return this.http.get(`${this.API_URL}/client-panel/reporting/get-employees-transactions${queryParams}`, this.httpOptions);
  }
  getEmployeesReportIndicators(){    
    return this.http.get(`${this.API_URL}/client-panel/reporting/reporting-indicators`, this.httpOptions);
  }
  summaryOfUsersByMonth( startMonth, endMonth, skip ){    
    let queryParams = `?limit=10&skip=${skip}`;

    if(startMonth && endMonth){
      queryParams += `&monthSince=${startMonth}&monthUntil=${endMonth}`
    }
    return this.http.get(`${this.API_URL}/client-panel/reporting/summary-of-user-reports-by-month${queryParams}`, this.httpOptions);
  }
  getMainReportFile(startMonth, endMonth){
    let queryParams = "";
    if(startMonth && endMonth) {
      queryParams += `?monthSince=${startMonth}&monthUntil=${endMonth}`
    }
    return this.http.get(`${this.API_URL}/client-panel/reporting/get-main-reporting-file${queryParams}`, {...this.filesHttpOptions,  responseType: 'blob'});
  }
  getEmployeesTransactionsFile(startMonth, endMonth){
    let queryParams = "";
    if(startMonth && endMonth) {
      queryParams += `?monthSince=${startMonth}&monthUntil=${endMonth}`
    }
    return this.http.get(`${this.API_URL}/client-panel/reporting/get-employees-transactions-file${queryParams}`, {...this.filesHttpOptions,  responseType: 'blob'});
  }

  /* VIDEO VIEWS CLIENT */
  getVideoViews( id, userId, skip){
    let queryParams = '';    
    if(userId){
      queryParams = `&user=${userId}&skip=${skip}`
    }
    else {  
        queryParams = `&skip=${skip}`
    }
    return this.http.get(`${this.API_URL}/video/get-company-video-views?video=${id}${queryParams}`, this.httpOptions);
  }
  getVideoViewsFile( id, userId){
      let queryParams = '';
      if(id && !userId){
        queryParams = `?video=${id}`
      }
      else if(!id && userId){
        queryParams = `?user=${userId}`
      }
      else if(id && userId){
        queryParams = `?video=${id}&user=${userId}`
      }
      else {  
          queryParams = ''
      }
      return this.http.get(`${this.API_URL}/video/get-company-video-views-file${queryParams}`, {...this.filesHttpOptions,  responseType: 'blob'});
  }
  /* END: VIDEO VIEWS CLIENT  */

  uploadToS3(file){
    return this.http.post(`${this.API_URL}/media/upload`,file, this.filesHttpOptions);
  }
  getTransactionsIndicators(){
    return this.http.get(`${this.API_URL}/client-panel/transactions/get-transactions-indicators`, this.httpOptions);
  }
  getTransactions(dateStart = '', dateEnd = '') {
    let filters = dateStart && dateEnd ? `?since=${dateStart}&until=${dateEnd}` : '';    
    return this.http.get(`${this.API_URL}/transaction/monthly-transactions${filters}`, this.httpOptions);
  }
  getTransactionsAdmin(dateStart = '', dateEnd = '') {
    let filters = dateStart && dateEnd ? `?since=${dateStart}&until=${dateEnd}` : '';    
    return this.http.get(`${this.API_URL}/transaction/monthly-transactions${filters}`, this.httpOptions);
  }
  getPaymentsIndicators(){
    return this.http.get(`${this.API_URL}/client-panel/payments/get-payment-information`, this.httpOptions);
  }
  getEmployeesIndicators(){    
    return this.http.get(`${this.API_URL}/client-panel/employees/employees-indicators?company=${JSON.parse(localStorage.getItem('user'))?.user?.client?.id}`, this.httpOptions);
  }
  /* ======= DASHBOARD ========= */
  getTopUsers(filter = '') {
    let filters = filter && `?filter=${filter}`;
    console.log('filters', filters);
    return this.http.get(`${this.API_URL}/client-panel/dashboard/top-5${filters}`, this.httpOptions);
  }
  getTopUsersAdmin(filter = '') {
    let filters = filter && `?filter=${filter}`;
    return this.http.get(`${this.API_URL}/admin-panel/top-5-clients${filters}`, this.httpOptions);
  }
  getDailyWithdrawalAmount() {
    return this.http.get(`${this.API_URL}/client-panel/dashboard/daily-withdrawal-amount`, this.httpOptions);
  }
  getDailyWithdrawalAmountAdmin() {
    return this.http.get(`${this.API_URL}/admin-panel/dashboard/daily-withdrawal-amount`, this.httpOptions);
  }
  getDailyUsersTransactions() {
    return this.http.get(`${this.API_URL}/client-panel/dashboard/users-information`, this.httpOptions);
  }
  getDailyUsersTransactionsAdmin() {
    return this.http.get(`${this.API_URL}/admin-panel/dashboard/users-and-clients-information`, this.httpOptions);
  }
  /* ======== LOGIN ========== */
  confirmAccount( password ) {
    return this.http.post(`${this.API_URL}/auth/confirm-account`, password, this.httpOptions);
  }
  updatePassword( password, token ) {
    return this.http.post(`${this.API_URL}/auth/reset-password/${token}`, password, this.httpOptions);
  }
  getConfigApp() {
    return this.http.get(`${this.API_URL}/configApp/`, this.httpOptions);
  }
  getEmployeesByClientId(page, clientId, limit){
    let queryParams = `?limit=${limit}&skip=${page}`;
    if(clientId){
      queryParams += `&company=${clientId}`
    }
    return this.http.get(`${this.API_URL}/employee${queryParams}`, this.httpOptions);
  }
  getInvoices(month, company, employee, invoiceType, number, correlative, skip, limit){
    let queryParams = `?skip=${skip}&limit=${limit}&populate=true`;
    if (month){
      queryParams += `&month=${month}`
    }
    if (company){
      queryParams += `&company=${company}`
    }
    if (employee){
      queryParams += `&employee=${employee}`
    }
    if (invoiceType){
      queryParams += `&type=${invoiceType}`
    }
    if (number){
      queryParams += `&number=${number}`
    }
    if (correlative){
      queryParams += `&correlativeNumber=${correlative}`
    }
    return this.http.get(`${this.API_URL}/invoice${queryParams}`, this.httpOptions);
  }
  getPromissoryNotes(month, company, employee, correlativeNumber, status, skip, limit){
    let queryParams = `?skip=${skip}&limit=${limit}&populate=true`;
    if (month){
      queryParams += `&month=${month}`
    }
    if (company){
      queryParams += `&company=${company}`
    }
    if (employee){
      queryParams += `&employee=${employee}`
    }
    if (correlativeNumber){
      queryParams += `&correlativeNumber=${correlativeNumber}`
    }
    if (status){
      queryParams += `&status=${status}`
    }
    return this.http.get(`${this.API_URL}/promissory-note${queryParams}`, this.httpOptions);
  }
  getDiscountAuthorization(month, company, employee, correlativeNumber, status, skip, limit){
    let queryParams = `?skip=${skip}&limit=${limit}&populate=true`;
    if (month){
      queryParams += `&month=${month}`
    }
    if (company){
      queryParams += `&company=${company}`
    }
    if (employee){
      queryParams += `&employee=${employee}`
    }
    if (correlativeNumber){
      queryParams += `&correlativeNumber=${correlativeNumber}`
    }
    if (status){
      queryParams += `&status=${status}`
    }
    return this.http.get(`${this.API_URL}/discount-authorization${queryParams}`, this.httpOptions);
  }
}
