import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ApiService } from './api.service';
import { Router, Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  API_URL = environment.api_url;
  httpHeaders;
  constructor(private http: HttpClient,
    private apiService: ApiService,
    private router: Router
    ) {    
    this.httpHeaders = new HttpHeaders({"Accept": "application/json", "Content-Type": "application/json"}); 

  }
  public get currentUserValue() {
    let userLocalStorage =  JSON.parse(localStorage.getItem('user')); 
    return userLocalStorage;
  }
  login(user) {
    return this.http.post(`${this.API_URL}/auth/login`, user);
  }
  forgotPassword(user) {
    return this.http.post(`${this.API_URL}/auth/forgot-password`, user);
  }
  confirmAccount( password ) {
    return this.http.post(`${this.API_URL}/auth/confirm-account`, password);
  }
  logout() {
    localStorage.clear();
    this.router.navigate(["/auth/login"]);
  }
}
