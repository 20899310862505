import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { CommonModule } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";

import { AdminLayoutComponent } from "./layouts/admin-layout/admin-layout.component";
import { AuthLayoutComponent } from "./layouts/auth-layout/auth-layout.component";
import { GuardGuard} from "../app/guard.guard"
import { Role } from '../app/models';

const routes: Routes = [
  {
    path: "",
    redirectTo: "dashboard",
    pathMatch: "full"
 },
  {
    path: "",
    component: AdminLayoutComponent,
    children: [
      {
        path: "",
        loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule),
        data: { roles: [
          Role.SuperAdmin,
          Role.FinancialAdmin,
          Role.Employee
          ]
        },
        canActivate: [GuardGuard]
      },
/*       {
        path: "",
        loadChildren: () => import('./pages/admin/clients/clients.module').then(m => m.ClientsModule),
        canActivate: [GuardGuard]
      }, */
      {
        path: "",
        loadChildren: () => import('./pages/transactions/transactions.module').then(m => m.TransactionsModule),
        canActivate: [GuardGuard]
      },
      {
        path: "",
        loadChildren: () => import('./pages/payments/payments.module').then(m => m.PaymentsModule),
        canActivate: [GuardGuard]
      },
      {
        path: "",
        loadChildren: () => import('./pages/employees/employees.module').then(m => m.EmployeesModule),
        canActivate: [GuardGuard]
      },
      {
        path: "",
        loadChildren: () => import('./pages/education/education.module').then(m => m.EducationModule),
        canActivate: [GuardGuard]
      },
      {
        path: "",
        loadChildren: () => import('./pages/reports/reports.module').then(m => m.ReportsModule),
        canActivate: [GuardGuard]
      },
      {
        path: "",
        loadChildren: () => import('./pages/bills/bills.module').then(m => m.BillsModule),
        canActivate: [GuardGuard]
      },
      {
        path: "admin",
        loadChildren: () => import('./pages/admin/admin.module').then(m => m.AdminModule),
        data: { roles: [
          Role.Admin,
          Role.SuperAdmin,
          Role.FinancialAdmin,
          Role.RequestsAdmin] 
        },
        canActivate: [GuardGuard]

      }
    ]
  },
/*   {
    path: "",
    component: AuthLayoutComponent,
    children: [
      {
        path: "examples",
        loadChildren: () => import('./layouts/auth-layout/auth-layout.module').then(m => m.AuthLayoutModule)
      }
    ]
  }, */
  {
    path: "auth",
    component: AuthLayoutComponent,
    children: [
      {
        path: "",
        loadChildren: () => import('./layouts/auth-layout/auth-layout.module').then(m => m.AuthLayoutModule)
      }
    ]
  },
  {
    path: "**",
    redirectTo: ""
  }
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes, {
      useHash: true
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
